export default {
    state: {
        framePhoneType: {
            "Pixel 3 (Black)": 0
        },
        languageKey: {
            "en": "EN",
            "af": "AF",
            "sq": "SQ",
            "am": "AM",
            "ar": "AR",
            "hy": "HY",
            "asAs": "AS_AS",
            "az": "AZ",
            "bn": "BN",
            "eu": "EU",
            "be": "BE",
            "bs": "BS",
            "bg": "BG",
            "ca": "CA",
            "zhHk": "ZH_HK",
            "zh": "ZH",
            "zhTw": "ZH_TW",
            "hr": "HR",
            "cs": "CS",
            "da": "DA",
            "nl": "NL",
            "enAu": "EN_AU",
            "enCa": "EN_CA",
            "enIn": "EN_IN",
            "enGb": "EN_GB",
            "enXc": "EN_XC",
            "et": "ET",
            "tl": "TL",
            "fi": "FI",
            "frCa": "FR_CA",
            "fr": "FR",
            "gl": "GL",
            "ka": "KA",
            "de": "DE",
            "el": "EL",
            "gu": "GU",
            "iw": "IW",
            "hi": "HI",
            "hu": "HU",
            "isIs": "IS_IS",
            "id": "ID",
            "itIt": "IT_IT",
            "ja": "JA",
            "knIn": "KN_IN",
            "kk": "KK",
            "km": "KM",
            "ko": "KO",
            "ky": "KY",
            "lo": "LO",
            "lv": "LV",
            "ltLt": "LT_LT",
            "mk": "MK",
            "ms": "MS",
            "ml": "ML",
            "mr": "MR",
            "mn": "MN",
            "my": "MY",
            "ne": "NE",
            "nb": "NB",
            "orOr": "OR_OR",
            "fa": "FA",
            "pl": "PL",
            "ptBr": "PT_BR",
            "ptPt": "PT_PT",
            "pa": "PA",
            "ro": "RO",
            "ru": "RU",
            "sr": "SR",
            "si": "SI",
            "sk": "SK",
            "sl": "SL",
            "es": "ES",
            "es419": "ES_419",
            "esUs": "ES_US",
            "sw": "SW",
            "sv": "SV",
            "ta": "TA",
            "te": "TE",
            "th": "TH",
            "tr": "TR",
            "uk": "UK",
            "ur": "UR",
            "uz": "UZ",
            "vi": "VI",
            "zu": "ZU"
        },
        allLanguages: {
            "en": "English",
            "af": "Afrikaans",
            "sq": "Albanian",
            "am": "Amharic",
            "ar": "Arabic",
            "hy": "Armenian",
            "asAs": "Assamese",
            "az": "Azerbaijani",
            "bn": "Bangla",
            "eu": "Basque",
            "be": "Belarusian",
            "bs": "Bosnian",
            "bg": "Bulgarian",
            "ca": "Catalan",
            "zhHk": "Chinese(Hong Kong)",
            "zh": "Chinese(Simplified)",
            "zhTw": "Chinese(Traditional)",
            "hr": "Croatian",
            "cs": "Czech",
            "da": "Danish",
            "nl": "Dutch",
            "enAu": "English(Australia)",
            "enCa": "English(Canada)",
            "enIn": "English(IN)",
            "enGb": "English(United Kingdom)",
            "enXc": "English(XC)",
            "et": "Estonian",
            "tl": "Filipino",
            "fi": "Finnish",
            "frCa": "French(Canada)",
            "fr": "French(France)",
            "gl": "Galician",
            "ka": "Georgian",
            "de": "German",
            "el": "Greek",
            "gu": "Gujarati",
            "iw": "Hebrew",
            "hi": "Hindi",
            "hu": "Hungarian",
            "isIs": "Icelandic",
            "id": "Indonesian",
            "itIt": "Italian",
            "ja": "Japanese",
            "knIn": "Kannada",
            "kk": "Kazakh",
            "km": "Khmer",
            "ko": "Korean",
            "ky": "Kyrgyz",
            "lo": "Lao",
            "lv": "Latvian",
            "ltLt": "Lithuanian",
            "mk": "Macedonian",
            "ms": "Malay",
            "ml": "Malayalam",
            "mr": "Marathi",
            "mn": "Mongolian",
            "my": "Myanmar",
            "ne": "Nepali",
            "nb": "Norwegian",
            "orOr": "Odia",
            "fa": "Persian",
            "pl": "Polish",
            "ptBr": "Portuguese(Brazil)",
            "ptPt": "Portuguese(Portugal)",
            "pa": "Punjabi",
            "ro": "Romanian",
            "ru": "Russian",
            "sr": "Serbian",
            "si": "Sinhala",
            "sk": "Slovak",
            "sl": "Slovenian",
            "es": "Spanish",
            "es419": "Spanish(Latin America)",
            "esUs": "Spanish(United States)",
            "sw": "Swahili",
            "sv": "Swedish",
            "ta": "Tamil",
            "te": "Telugu",
            "th": "Thai",
            "tr": "Turkish",
            "uk": "Ukrainian",
            "ur": "Urdu",
            "uz": "Uzbek",
            "vi": "Vietnamese",
            "zu": "Zulu"
        },
        languages: {
            "EN": "English",
            "AF": "Afrikaans",
            "SQ": "Albanian",
            "AM": "Amharic",
            "AR": "Arabic",
            "HY": "Armenian",
            "AS_AS": "Assamese",
            "AZ": "Azerbaijani",
            "BN": "Bangla",
            "EU": "Basque",
            "BE": "Belarusian",
            "BS": "Bosnian",
            "BG": "Bulgarian",
            "CA": "Catalan",
            "ZH_HK": "Chinese(Hong Kong)",
            "ZH": "Chinese(Simplified)",
            "ZH_TW": "Chinese(Traditional)",
            "HR": "Croatian",
            "CS": "Czech",
            "DA": "Danish",
            "NL": "Dutch",
            "EN_AU": "English(Australia)",
            "EN_CA": "English(Canada)",
            "EN_IN": "English(IN)",
            "EN_GB": "English(United Kingdom)",
            "EN_XC": "English(XC)",
            "ET": "Estonian",
            "TL": "Filipino",
            "FI": "Finnish",
            "FR_CA": "French(Canada)",
            "FR": "French(France)",
            "GL": "Galician",
            "KA": "Georgian",
            "DE": "German",
            "EL": "Greek",
            "GU": "Gujarati",
            "IW": "Hebrew",
            "HI": "Hindi",
            "HU": "Hungarian",
            "IS_IS": "Icelandic",
            "ID": "Indonesian",
            "IT_IT": "Italian",
            "JA": "Japanese",
            "KN_IN": "Kannada",
            "KK": "Kazakh",
            "KM": "Khmer",
            "KO": "Korean",
            "KY": "Kyrgyz",
            "LO": "Lao",
            "LV": "Latvian",
            "LT_LT": "Lithuanian",
            "MK": "Macedonian",
            "MS": "Malay",
            "ML": "Malayalam",
            "MR": "Marathi",
            "MN": "Mongolian",
            "MY": "Myanmar",
            "NE": "Nepali",
            "NB": "Norwegian",
            "OR_OR": "Odia",
            "FA": "Persian",
            "PL": "Polish",
            "PT_BR": "Portuguese(Brazil)",
            "PT_PT": "Portuguese(Portugal)",
            "PA": "Punjabi",
            "RO": "Romanian",
            "RU": "Russian",
            "SR": "Serbian",
            "SI": "Sinhala",
            "SK": "Slovak",
            "SL": "Slovenian",
            "ES": "Spanish",
            "ES_419": "Spanish(Latin America)",
            "ES_US": "Spanish(United States)",
            "SW": "Swahili",
            "SV": "Swedish",
            "TA": "Tamil",
            "TE": "Telugu",
            "TH": "Thai",
            "TR": "Turkish",
            "UK": "Ukrainian",
            "UR": "Urdu",
            "UZ": "Uzbek",
            "VI": "Vietnamese",
            "ZU": "Zulu"
        }
    }
}