<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
import * as firebase from 'firebase/app'
import * as analytics from 'firebase/analytics'

const firebaseConfig = {
  apiKey: "AIzaSyAg5hGD_yastgkSEd2s5ddSea2BhOo3azA",
  authDomain: "kola-cbb.firebaseapp.com",
  projectId: "kola-cbb",
  storageBucket: "kola-cbb.appspot.com",
  messagingSenderId: "225319898424",
  appId: "1:225319898424:web:ed14f17fa64d1a02ac85cd",
  measurementId: "G-FGNMPYZP4W"
};

const firebaseApp = firebase.initializeApp(firebaseConfig);
const appAnalytics = analytics.getAnalytics(firebaseApp);

export default {
  name: 'app',
  components: {
  },
  methods: {
    clickMain() {
      this.$router.push("/")
    },
  }
}
</script>

<style lang="less">
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  font-weight: 700;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
//  color: #2c3e50;

}
html, body {
  margin: 0;
  padding: 0;
}


.container {
  margin-top: 40px;
}

.App {
  width: 100%;
  margin: auto
}

@media only screen and (min-width: 1200px) {
  .App {
    width: 1200px
  }
}
.AppCard {
  margin-top: 80px;
  border-radius: 5px;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 20px;
}

.el-header {
  padding: 0;
}

</style>
