import Vue from 'vue'
import VueRouter from 'vue-router'

const Strings = () => import('../views/project/Strings.vue');
const Submission = () => import( '../views/project/Submission.vue');
const Project = () => import("../views/project/Project.vue")
const Main = () => import("../views/home/Main")
const ProjectsManager = () => import("../views/manager/ProjectsManager.vue")
const ProjectsList = () => import("../views/manager/ProjectList.vue")
const NewProject = () => import("../views/manager/NewProject.vue")

const Home = () => import("../views/home/Home")
const Pricing = () => import("../views/home/Pricing")
const Support = () => import("../views/home/Support")
const UserTranslate =() => import("../views/UserTranslate")
const MultiTranslate =() => import("../views/home/MultiTranslate.vue")

const ProjectDashboard =() => import("../views/project/Dashboard")
const StoreListing =() => import("../views/project/StoreListing")
const Screenshots =() => import("../views/project/Screenshots")

const ScreenshotTool =() => import("../views/home/ScreenshotTool.vue")

const Dating =() => import("../views/Dating.vue")
const AiModel =() => import("../views/AiModel.vue")




import Cookie from "js-cookie"

Vue.use(VueRouter)

const routes = [
    {
        path: '/', name: 'main', component: Main, redirect: '/home',
        children: [
            {path: '/home', name: 'home', component: Home},
            {path: '/pricing', name: 'pricing', component: Pricing},
            {path: '/translator', name: 'translator', component: MultiTranslate},
            {path: '/support', name: 'support', component: Support},
            {path: '/screenshot', name: 'support', component: ScreenshotTool},
        ]
    },
    {
        path: '/projects', component: ProjectsManager, redirect: '/projects/list',
        children: [
            {path: '/projects/list', name: 'list', component: ProjectsList},
            {path: '/projects/create', name: 'create', component: NewProject}
        ]
    },

    {
        path: '/project', component: Project,
        children: [
            {path: '/project/:id/dashboard', component: ProjectDashboard},
            {path: '/project/:id/strings', component: Strings},
            {path: '/project/:id/submission', component: Submission},
            {path: '/project/:id/listing', component: StoreListing},
            {path: '/project/:id/screenshots', component: Screenshots}
        ]
    },
    {path: '/translate/:id', name: 'translate', component: UserTranslate},
    {path: '/dating', name: 'dating', component: Dating},
    {path: '/ai', name: 'ai', component: AiModel},

];

const router = new VueRouter({
    mode: 'history',
    routes
})

router.beforeEach((to, from, next) => {
    const token = Cookie.get('token')
    // token 是否过期或者不存在
    console.log(to.name)
    if (!token && !to.name && to.name !='main') {
        next({name: "main"})
    } else {
        next()
    }
})

export default router
