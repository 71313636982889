export default {
    state: {
        isCollapse: false, // 控制菜单的展开关闭
        projectName: 'Projects', // 当前项目的名字
        currentTabName: 'Strings', // 当前 Tab 的名字
        projectData: {} // Project 页面中，将当前页面中的公共信息存下来
    },
    mutations: {
        collapseMenu(state) {
            state.isCollapse = !state.isCollapse
        },
        updateProjectName(state, value) {
            state.projectName = value;
        },
        updateTabName(state, value) {
            state.currentTabName = value
        },
        updateProjectData(state, value) {
            state.projectData = value
        }
    }
}